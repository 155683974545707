import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import union from "lodash/union";
import { imageUrlFor } from "../../helpers/images";
import { SanityImageObject } from "@sanity/image-url/lib/types/types";
import { replaceDoubleQuotes } from "../../helpers/replaceDoubleQuotes";

interface SEOAuthor {
  name?: string;
  twitterHandle?: string;
}
interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Array<{
    name: string;
    content: any;
  }>;
  keywords?: Array<string>;
  title?: string;
  socialImage?: SanityImageObject;
  author?: SEOAuthor;
  siteUrl?: string;
  schema?: string;
}

interface SEOStaticQuery {
  site: {
    title: string;
    siteUrl: string;
    description: string;
    keywords: string;
    seoTitle: string;
    socialImage: SanityImageObject;
    author: SEOAuthor;
  };
  show: {
    attributes: {
      feed_url: string;
    };
  };
}

const SEO: React.FC<SEOProps> = (props) => {
  const {
    site,
    show: {
      attributes: { feed_url }
    }
  } = useStaticQuery<SEOStaticQuery>(seoQuery);
  const {
    description,
    title,
    socialImage,
    author,
    siteUrl,
    seoTitle
  } = site;
  const { pathname } = useLocation();
  const siteTitle = props.title || title;
  const siteImage = props.socialImage || socialImage;
  const metaImage = siteImage
    ? imageUrlFor(siteImage)
    : imageUrlFor(socialImage);
  const url = `${siteUrl}${pathname}`;
  const siteKeywords =
    site.keywords && site.keywords.length > 0 ? site.keywords : [];
  const propsKeywords = props.keywords ? props.keywords : [];
  const keywords = union(siteKeywords, propsKeywords);
  const escapedDescription = replaceDoubleQuotes(
    props.description || description || ""
  );
  const meta: Array<React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >> = [
      description
        ? {
          name: "description",
          content: escapedDescription
        }
        : {},
      {
        name: "keywords",
        content: keywords.length > 0 ? keywords.join(", ") : ""
      },
      metaImage
        ? {
          name: "image",
          content: metaImage as any
        }
        : {},
      { property: "og:url", content: url },
      siteTitle
        ? {
          property: "og:title",
          content: siteTitle
        }
        : {},
      description
        ? { property: "og:description", content: escapedDescription }
        : {},
      metaImage
        ? {
          property: "og:image",
          content: metaImage as any
        }
        : {},
      { name: "twitter:card", content: "summary_large_image" },
      author?.twitterHandle
        ? {
          name: "twitter:creator",
          content: author.twitterHandle
        }
        : {},
      siteTitle ? { name: "twitter:title", content: siteTitle } : {},
      description
        ? { property: "twitter:description", content: description }
        : {},
      metaImage
        ? {
          name: "twitter:image",
          content: metaImage as any
        }
        : {}
    ];
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      titleTemplate={
        title === siteTitle ? `%s | ${seoTitle}` : `${siteTitle} | ${seoTitle}`
      }
      meta={meta}
    >
      {feed_url && (
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Podcast"
          href={feed_url}
        />
      )}
      {props.schema && (
        <script type="application/ld+json">{props.schema}</script>
      )}
    </Helmet>
  );
};

export default SEO;

const seoQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings {
      title
      siteUrl
      description
      keywords
      seoTitle
      socialImage {
        asset {
          _id
        }
      }
      author {
        name
        twitterHandle
      }
    }
    show: transistorShow {
      attributes {
        feed_url
      }
    }
  }
`;
