import clientConfig from "../../client-config";
import imageUrlBuilder from "@sanity/image-url";
import {
  SanityImageHotspot,
  SanityImageObject
} from "@sanity/image-url/lib/types/types";

const { dataset, projectId } = clientConfig.sanity;
const builder = imageUrlBuilder({
  projectId: projectId as string,
  dataset: dataset as string
});

export function imageUrlFor(source: SanityImageObject) {
  return builder.image(source);
}

export function getImageObjectPosition({ x, y }: SanityImageHotspot) {
  return `${x * 100}% ${y * 100}%`;
}
